import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Meta from '../components/Meta';
import axios from 'axios';
import { LazyImage, placeholder, errorPlaceholder } from '../components/LazyImage';
import ErrorRetry, { LoadingText } from '../components/ErrorRetry';
import SearchPagination from '../components/SearchPagination';
import {BannerAds,GridAds } from '../components/AdsComponent';

const Search = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [luj, setLuj] = useState('');

  const { query, p } = useParams();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState(query || '');
  const [currentPage, setCurrentPage] = useState(Number(p) || 1);

  const fetchData = async (searchValue, page) => {
    setLoading(true);
    setError(false);

    try {
      const response = await axios.get(`/api/searchs?key=${searchValue}&p=${page}/`);
      if (response.status === 200) {

        const dataArray = response.data.data || [];
        for (let i = 0; i < dataArray.length; i++) {
          if(dataArray[i].genres == "1"){
             dataArray[i].litpic = "https://2e68cq.8goaimpicg.com:8443/p2/"+dataArray[i].litpic+".webp";
          }else{
             dataArray[i].litpic = "https://5gixb.xyz:1443/pic/"+dataArray[i].litpic+".webp";
          }
        }
        setData(dataArray || []);
        setTotalPage(response.data.page || 0);
        setIsLastPage(response.data.page === page);
      } else {
        console.error('Failed to fetch data:', response.data);
        setError(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > totalPage) {
      alert(`已超出最大页 ${totalPage}`);
    } else {
      setCurrentPage(newPage);
      navigate(`/search/${searchQuery}/${newPage}/`);
      fetchData(searchQuery, newPage);
    }
  };

  const handleSearch = () => {
    if (!inputValue.trim()) {
      alert('请输入搜索关键词');
    } else {
      setSearchValue(inputValue);
      setSearchQuery(inputValue);
      setCurrentPage(1);
      navigate(`/search/${inputValue}/1/`);
      fetchData(inputValue, 1);
    }
  };

  function getType(){
    var sys = {win:false,mac:false,x11:false,ipad:false};
    var p = navigator.platform;
    sys.win = p.indexOf("Win") == 0;
    sys.mac = p.indexOf("Mac") == 0;
    sys.x11 = p.indexOf("X11") == 0;
    sys.ipad = navigator.userAgent.match(/iPad/i) != null ? true :false;
    if(sys.win || sys.mac ||sys.x11 || sys.ipad){
      setLuj("pp");
    }else{
      var a = new Date(new Date().getTime()+(parseInt(new Date().getTimezoneOffset()/60) + 8)*3600*1000);
      var b = new Date();
      if(a.getHours() == b.getHours()){
        setLuj("mm");
      }
    }
  }

  const handleSearchKey = (keyword) => {
    if(keyword == "大陆"){
      if (luj == 'mm') {
        window.location = '/index2.html';
      }else{
        setInputValue(keyword);
        setTriggerFetch(true);
      }
    }else{
      setInputValue(keyword);
      setTriggerFetch(true);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    getType();
    if (searchQuery && currentPage) {
      fetchData(searchQuery, currentPage);
    }
  }, [searchQuery, currentPage]);

  useEffect(() => {
    getType();
    if (triggerFetch) {
      handleSearch();
      setTriggerFetch(false);
    }
  }, [triggerFetch]);

  useEffect(() => {
    getType();
    setLoading(false);
  }, []);

  if (loading) {
    return <LoadingText />;
  }

  if (error) {
    return <ErrorRetry title={'推荐视频'} onClick={() => fetchData(searchQuery, currentPage)} />;
  }

  const dum = (n) => {
    if (luj == 'mm') {
      window.location = '/index2.html';
    }else{
      navigate(`/${n}/`);
    }
  };

  return (
    <>
      <Meta title="搜索-3B" description="欢迎来到3B，这里有最精彩的在线视频，每天更新永久免费" keywords="3B, 搜索, 在线视频, 每天更新, 永久免费" />
      <BannerAds />
      <GridAds/>
      <div className="flex items-center space-x-2">
        <input
          type="text"
          placeholder="请输入搜索内容"
          className="px-4 py-2 w-80 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          style={{ color: 'black' }}
          value={inputValue}
          onChange={handleInputChange}
        />
        <button
          className="px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-opacity-50"
          style={{
            backgroundColor: 'rgb(51 51 51 / var(--tw-bg-opacity))', // Custom background color
            color: '#FFFFFF', // Text color
            borderRadius: '0.375rem',
            padding: '8px 16px',
            outline: 'none',
          }}
          onClick={handleSearch}
        >
          搜索
        </button>
      </div>
      <div className="grid grid-cols-4 gap-4 p-4">
        {['国产', '欧美', '日韩', '大陆'].map((keyword) => (
          <div
            key={keyword}
            className="border border-gray-300 p-2 text-center cursor-pointer"
            onClick={() => handleSearchKey(keyword)}
          >
            {keyword}
          </div>
        ))}
      </div>
      <div className="content columns-2 xs:columns-1 s:columns-2 sm:columns-3 md:columns-4 lg:columns-4 gap-2 mb-4 mt-4">
        {data.length > 0 ? (
          data.map((item,index) => (
            <div key={item.id} className="break-inside-avoid mb-4">
              <Link to={`/${item.id}/`} className="break-inside-avoid mb-4"  onClick={index === 1 ? (e) => {  e.preventDefault();  dum(item.id);} : undefined}>
                <LazyImage
                  className="w-full h-auto object-cover"
                  src={`${item.litpic}`}
                  alt={item.title}
                  placeholder={placeholder(300, 200)}
                  errorPlaceholder={errorPlaceholder(300, 200, item.title)}
                  width={300}
                  height={200}
                />
                <p className="text-center mt-2">{item.title}</p>
              </Link>
            </div>
          ))
        ) : (
          <p>没有找到结果</p>
        )}
      </div>
      {totalPage ? (
        <SearchPagination currentPage={Number(currentPage)} totalPage={totalPage} isLastPage={isLastPage} onPageChange={handlePageChange} />
      ) : null}
    </>
  );
};

export default Search;
